import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const RequestForm = ({ isVisible, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [isMessageSuccess, setIsMessageSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Отправка данных через EmailJS:', formData);

    emailjs.send(
      'service_02z7avm', // Замените на ваш Service ID
      'template_nakroyalexpo', // Замените на ваш Template ID
      {
        name: formData.name,
        phone: formData.phone,
      },
      'Ih2whiRIFcNdU7epc' // Замените на ваш Public Key из EmailJS
    )
    .then(response => {
      console.log('Сообщение отправлено успешно:', response.status, response.text);
      setIsMessageSuccess(true);
      setResponseMessage('Заявка отправлена!');
      setTimeout(() => {
        setResponseMessage('');
        setIsMessageSuccess(false);
        setFormData({
          name: '',
          phone: '',
          message: ''
        });
        onClose();
      }, 1000); // Закрыть форму через 1 секунду
    })
    .catch(error => {
      console.error('Ошибка при отправке сообщения через EmailJS:', error);
      setResponseMessage('Ошибка при отправке сообщения.');
    });
  };

  if (!isVisible) return null;

  return (
    <div className="request-form-overlay">
      <div className="request-form">
        <button onClick={onClose} className="close-button">X</button>
        <h2>Отправить заявку</h2>
        {responseMessage && <p>{responseMessage}</p>}
        {isMessageSuccess ? (
          <p>{responseMessage}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <label>
              Имя:
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </label>
            <label>
              Телефон:
              <input type="text" placeholder='8-123-456-78-90' name="phone" value={formData.phone} onChange={handleChange} required />
            </label>

            <button type="submit">Отправить</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default RequestForm;
